import { combineReducers } from "@reduxjs/toolkit";
import { calendarInfoSlice } from "./slices/calendarInfoSlice";
import { dateNavigationSlice } from "./slices/dateNavigationSlice";
import { intervalsSlice } from "./slices/intervalsSlice";
import { selectionSlice } from "./slices/selectionSlice";
import { uiSlice } from "./slices/uiSlice";
import { usersSlice } from "./slices/usersSlice";


const rootReducer = combineReducers({
	selection: selectionSlice.reducer,
	dateNavigation: dateNavigationSlice.reducer,
	calendarInfo: calendarInfoSlice.reducer,
	users: usersSlice.reducer,
	intervals: intervalsSlice.reducer,
	ui: uiSlice.reducer,
});

const reducer = (state, action) => {
	if (action.type === "CLEAR_STORE") {
		return rootReducer(undefined, action);
	}

	return rootReducer(state, action);
};

export default reducer;
