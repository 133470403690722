import * as React from "react";
import { connect } from 'react-redux';
import { _ } from "../i18n/i18n";

function UserItem({username, currentUsername}) {



    return (
        <div className="">
            {currentUsername === username ? _("You") : username}
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentUsername: state.users.currentUsername
    };
};

export default connect(mapStateToProps)(UserItem);