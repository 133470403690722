import Interval from "luxon/src/interval.js";

export const getHours = (date) => {
	const hours = [date.startOf("day")];

	for (let i = 1; i < 24; i++) {
		hours[i] = hours[i - 1].plus({ hours: 1 });
	}

	return hours;
};

export const selectionToIntervals = (startDate, endDate) => {
	if(!startDate) return [];
	if(!endDate) endDate = startDate;
	const temp = startDate;
	if (endDate < startDate) {
		startDate = endDate;
		endDate = temp;
	}

	const startDay = startDate.day;
	const endDay = endDate.day;

	const startHour = Math.min(startDate.hour, endDate.hour);
	const endHour = Math.max(startDate.hour, endDate.hour);

	const startMinute = startDate.hour < endDate.hour ? startDate.minute : endDate.minute;
	const endMinute = startDate.hour < endDate.hour ? endDate.minute : startDate.minute;

	startDate = startDate.set({day: startDay, hour: startHour, minute: startMinute});
	endDate = startDate.set({day: endDay, hour: endHour, minute: endMinute});
	

	const endOnSameDay = startDate.set({hour: endDate.hour, minutes: endDate.minute});

	const numberOfIntervals = Interval.fromDateTimes(startDate, endDate.plus({minute: 1})).length("days");

	const intervals = [];
	for (let i = 0; i < numberOfIntervals; i++) {
		
		const newInterval = Interval.fromDateTimes(startDate.plus({days: i}), endOnSameDay.plus({days: i, minutes: 1}));
		intervals.push(newInterval);		
	}

	return intervals;
};
