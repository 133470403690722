import * as React from "react";
import AppButton from "./buttons/AppButton";
import { _ } from "../i18n/i18n";
import { connect } from "react-redux";
import { createNewCalendar } from "../store/slices/calendarInfoSlice";
import { Icon } from "semantic-ui-react";

function ShareCalendar({ dispatch, calendarURL }) {
	const saveAndShare = React.useCallback(async () => {
		dispatch(createNewCalendar());
	}, [dispatch]);

	if (calendarURL) {
		return null;
	}

	return (
		<div className="share-button-container">
			<AppButton onClick={saveAndShare} primary size="huge">
				<Icon name="share" />
				{_("Create and share")}
			</AppButton>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		calendarURL: state.calendarInfo.url,
	};
};

export default connect(mapStateToProps)(ShareCalendar);
