import Interval from "luxon/src/interval.js";
import DateTime from "luxon/src/datetime.js";
import { Interval as DBInterval } from "../debug_db/models";

export const intervalModelToLuxon = (modelInstance) => {
	const start = DateTime.fromMillis(parseInt(modelInstance.startDate));
	const end = DateTime.fromMillis(parseInt(modelInstance.endDate));

	return Interval.fromDateTimes(start, end);
};

export const luxonIntervalToModel = (username, calendarid, luxonInterval) => {
	const interval = new DBInterval(
		null,
		username,
		calendarid,
		luxonInterval.start.toMillis().toString(),
		luxonInterval.end.toMillis().toString()
	);

	return interval;
};
