import { createSlice } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import * as customQueries from "../../graphql/customQueries";
import * as mutations from "../../graphql/mutations";
import { intervalModelToLuxon } from "../../tools/intervalProcessor";
import { saveAllIntervals, setAllIntervals } from "./intervalsSlice";
import { setLoadingCalendar } from "./uiSlice";
import { setAllUsernames } from "./usersSlice";

const crypto = require("crypto");

export const calendarInfoSlice = createSlice({
	name: "calendarInfo",
	initialState: {},
	reducers: {
		setCalendar: (state, { payload: calendar }) => calendar,
	},
});

export const { setCalendar, setCalendarName, setCalendarDescription } = calendarInfoSlice.actions;

export function loadCalendar(url) {
	return async (dispatch, getState) => {
		dispatch(setLoadingCalendar(true));

		// Api call
		const calendarResponse = await API.graphql(graphqlOperation(customQueries.getCalendarByURL, { url: url }));
		const calendar = calendarResponse.data.calendarByURL.items.pl_first();

		dispatch(setCalendar(calendar || {}));

		const map = calendar.intervals.items.pl_groupby("username");

		for (const [key, value] of Object.entries(map)) {
			map[key] = value.map((i) => intervalModelToLuxon(i));
		}

		dispatch(setAllIntervals(map));
		dispatch(setAllUsernames(Object.keys(map)));
		dispatch(setLoadingCalendar(false));
	};
}

export function createNewCalendar() {
	return async (dispatch, getState) => {
		const state = getState();
		const calendarInfo = state.calendarInfo;

		dispatch(setLoadingCalendar(true));

		// Api call
		const createInfo = await API.graphql(graphqlOperation(mutations.createCalendar, { input: calendarInfo }));
		let newCalendar = createInfo.data.createCalendar;

		const id = newCalendar.id;
		const url = crypto.createHash("sha1").update(id).digest("hex");

		newCalendar.url = url;

		const updateInfo = await API.graphql(
			graphqlOperation(mutations.updateCalendar, { input: { id: newCalendar.id, url } })
		);
		newCalendar = updateInfo.data.updateCalendar;

		dispatch(setCalendar(newCalendar));

		window.history.pushState("", "", `/plan/${newCalendar.url}`);

		dispatch(saveAllIntervals());
		dispatch(setLoadingCalendar(false));
	};
}

export function updateCurrentCalendar(newData) {
	return async (dispatch, getState) => {
		let calendarInfo = getState().calendarInfo;

		calendarInfo = { ...calendarInfo, ...newData };
		dispatch(setCalendar(calendarInfo));

		if (calendarInfo.id) {
			const updateData = {
				id: calendarInfo.id,
				name: calendarInfo.name,
				description: calendarInfo.description,
				url: calendarInfo.url,
			};

			await API.graphql(graphqlOperation(mutations.updateCalendar, { input: updateData }));
		}
	};
}
