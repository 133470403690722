import * as React from "react";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import { loadCalendar } from "../store/slices/calendarInfoSlice";
import { setFocusedDate } from "../store/slices/dateNavigationSlice";
import { saveSelection } from "../store/slices/selectionSlice";
import CalendarInfo from "./CalendarInfo";
import CalendarLink from "./CalendarLink";
import DateNavigator from "./DateNavigator";
import Logo from "./Logo";
import ShareCalendar from "./ShareCalendar";
import ToggleEveryoneOption from "./ToggleEveryoneOption";
import UserInfo from "./UserInfo";
import Week from "./Week";
const { DateTime } = require("luxon");

function Calendar({ appRef, dispatch, focusedDate, createdAt, loadingCalendar }) {
	const calendarUID = window.location.pathname.substr(6);

	React.useEffect(() => {
		if (calendarUID) dispatch(loadCalendar(calendarUID));
	}, [dispatch, calendarUID]);

	React.useEffect(() => {
		// Start at when the calendar is created... not sure if it is the greatest idea.
		if (createdAt) {
			const newFocus = DateTime.fromISO(createdAt);
			dispatch(setFocusedDate(newFocus));
		}
	}, [dispatch, createdAt]);

	return (
		<Segment loading={loadingCalendar} className="calendar" onMouseUp={() => dispatch(saveSelection())}>
			<Logo />
			<div className="page-top">
				<CalendarInfo />
				<CalendarLink />
				<ShareCalendar />
			</div>

			<DateNavigator />
			<div className="info-area">
				<UserInfo />
			</div>

			<Week focusedDate={focusedDate} appRef={appRef} />
			
			<ToggleEveryoneOption />
		</Segment>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		focusedDate: state.dateNavigation.focusedDate,
		createdAt: state.calendarInfo.createdAt,
		loadingCalendar: state.ui.loadingCalendar,
	};
};

export default connect(mapStateToProps)(Calendar);
