import { getHours } from "../tools/dates";
import HourSection from "./HourSection";

function Hours({ date }) {
	const hours = getHours(date);

	return (
		<div className="hours-options">
			{hours.map((h, idx) => (
				<HourSection key={idx} hour={h} />
			))}
		</div>
	);
}

export default Hours;
