export class Calendar {
	constructor(id, name, description, url) {
		this.id = id;
		this.name = name;
		this.description = description;
		this.url = url;
	}
}

export class Interval {
	constructor(id, username, calendarid, startDate, endDate) {
		this.id = id;
		this.username = username;
		this.calendarid = calendarid;
		this.startDate = startDate;
		this.endDate = endDate;
	}
}
