import * as React from "react";
import Hour from "./Hour";

function HourSection({ hour }) {
	const halfHour = React.useMemo(() => hour.plus({ minutes: 30 }), [hour]);

	return (
		<div className="hour-section">
			<Hour hour={hour}/>
			<Hour hour={halfHour}/>
		</div>
	);
}


export default HourSection;
