import * as React from "react";
import { connect } from "react-redux";
import { Button, Header, Icon, Input, Segment } from "semantic-ui-react";
import { _ } from "../i18n/i18n";

function CalendarLink({ calendarURL }) {
	if (!calendarURL) {
		return null;
	}

	const fullUrl = `https://www.whendowemeet.com/plan/${calendarURL}`;

	return (
		<Segment className="share-box" style={{ alignItems: "center" }}>
			<Header>{_("Share this link to invite people to this planner.")}</Header>
			<Input
				className="not-mobile-small-only"
				action={{
					labelPosition: "right",
					icon: "copy",
					content: _("Copy"),
					onClick: () => {
						navigator.clipboard.writeText(fullUrl);
					},
				}}
				value={fullUrl}
			/>

			<Input className="mobile-small-only" value={fullUrl} style={{with: "100%"}} />
			<Button
				className="mobile-small-only"
				style={{ marginTop: 8 }}
				onClick={() => {
					navigator.clipboard.writeText(fullUrl);
				}}
			>
				<Icon name="copy" />
				{_("Copy")}
			</Button>

			<div className="hint" style={{ marginTop: 8 }}>
				{_("Anybody with the link will be able to add their own availability for the event")}
			</div>
		</Segment>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		calendarURL: state.calendarInfo.url,
	};
};

export default connect(mapStateToProps)(CalendarLink);
