import { createSlice } from "@reduxjs/toolkit";
import { getCookie, setCookie } from "../../tools/cookies";

export const usersSlice = createSlice({
	name: "users",
	initialState: {
		currentUsername: getCookie("username") || "",
		allUsernames: [],
	},
	reducers: {
		setUsername: (state, { payload: username }) => {
			state.currentUsername = username;
		},
		setAllUsernames: (state, { payload: allUsernames }) => {
			state.allUsernames = allUsernames;
		},
		addUsername: (state, { payload: username }) => {
			if (!state.allUsernames.includes(username)) {
				state.allUsernames.push(username);
			}
		},
	},
});

export const { setUsername, setAllUsernames, addUsername } = usersSlice.actions;

export function saveUsername(username) {
	return async (dispatch, getState) => {
		dispatch(setUsername(username));
		setCookie("username", username);
	};
}
