export const getCalendarByURL = /* GraphQL */ `
	query calendarByURL($url: String!) {
		calendarByURL(url: $url) {
			items {
				id
				name
				description
				url
				intervals {
					items {
						id
						username
						calendarID
						startDate
						endDate
						createdAt
						updatedAt
					}
					nextToken
				}
                createdAt
                updatedAt
			}
		}
	}
`;
