import * as React from "react";
import { Button, Form, Input, TextArea } from "semantic-ui-react";
import AppButton from "../buttons/AppButton";
import { isMobile } from "react-device-detect";

function EditableText({ value, placeholder, useTextArea, onChange, textClassName, style, className }) {
	const [editing, setediting] = React.useState(false);
	const [changedValue, setchangedValue] = React.useState(value || "");

	const onClick = React.useCallback(() => {
		if (!isMobile) return;
		setediting(true);
	}, []);

	const startEdit = React.useCallback(() => {
		setediting(true);
	}, []);

	const save = React.useCallback(() => {
		setediting(false);
		onChange(changedValue);
	}, [changedValue, onChange]);

	const cancel = React.useCallback(() => {
		setchangedValue(value);
		setediting(false);
	}, [value]);

	const usePlaceholder = !value;

	const ElementTag = useTextArea ? TextArea : Input;

	if (editing) {
		return (
			<div
				className={["editable-text editing", useTextArea ? "text-area" : "input", className].toStyle()}
				style={style}
			>
				<Form>
					<ElementTag
						type="text"
						value={changedValue}
						onChange={(e) => setchangedValue(e.target.value)}
						autoFocus
						placeholder={placeholder}
					/>
				</Form>

				<Button.Group className="buttons">
					<AppButton className="transparent" onClick={save} icon="check" />
					<AppButton className="transparent" onClick={cancel} icon="cancel" />
				</Button.Group>
			</div>
		);
	}

	return (
		<div
			className={["editable-text row result", usePlaceholder && "placeholder", className].join(" ")}
			style={style}
			onClick={onClick}
		>
			{!isMobile && <AppButton className="transparent" onClick={startEdit} icon="edit" />}
			<p className={textClassName}>{value || placeholder}</p>
		</div>
	);
}

export default EditableText;
