import Amplify from "aws-amplify";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { Provider as ReduxProvider } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { Container } from "semantic-ui-react";
import "./App.css";
import awsconfig from "./aws-exports";
import Calendar from "./components/Calendar";
import SavingBar from "./components/SavingBar";
import store from "./store/store";

require("./debug_db/models");
require("./tools/array");

Amplify.configure(awsconfig);

function App() {
	const appRef = React.useRef();

	const Tag = isMobile ? "div" : Container;

	return (
		<ReduxProvider store={store}>
			<div className="App" ref={appRef}>
				<Tag className="App-container">
					<Calendar appRef={appRef} />
				</Tag>
				<Container>
					<div style={{ marginTop: 12, color: "white", opacity: 0.2, textAlign: "center" }}>v0.21</div>
					<div style={{textAlign: "center" }}>
						<a href="mailto:contact@rolegate.com" target="_blank">contact</a>
					</div>
				</Container>
				<SavingBar/>
			</div>
		</ReduxProvider>
	);
}

export default App;
