export const setCookie = (name, value) => {
	document.cookie = `PLNR_${name}=${value}`;
};

export const getCookie = (name) => {
	return document.cookie
		.split("; ")
		.find((row) => row.startsWith(`PLNR_${name}=`))
		?.split("=")[1];
};
