import * as React from "react";
import { connect } from "react-redux";
import { Icon, Popup } from "semantic-ui-react";
import { _ } from "../i18n/i18n";
import { setDeleting, setSelectEndDate, setSelectStartDate } from "../store/slices/selectionSlice";
import UserList from "./UsersList";


function Hour({ hour, dispatch, selection, intervals, username, totalUsers, showEveryone }) {	
	const [showWarning, setshowWarning] = React.useState(false);

	const userIntervals = React.useMemo(() => {
		return intervals[username] || [];
	}, [intervals, username]);

	const currentlySelected = React.useMemo(() => {
		if (selection.currentIntervals.some((interval) => interval.contains(hour))) return !selection.deleting;
		return false;
	}, [selection, hour]);

	const userSelected = React.useMemo(() => {
		if (selection.deleting) {
			if (selection.currentIntervals.some((interval) => interval.contains(hour))) return false;
		}
		if (userIntervals.some((interval) => interval.contains(hour))) return true;
		return false;
	}, [hour, userIntervals, selection]);

	const overlappingUsers = React.useMemo(() => {
		const users = [];
		for (const [key, value] of Object.entries(intervals)) {
			if (value.some((v) => v.contains(hour))) {
				users.push(key);
			}
		}
		return users;
	}, [intervals, hour]);

	const othersOverlapping = React.useMemo(() => {
		if (!showEveryone) return [];
		return overlappingUsers.filter((u) => u !== username);
	}, [overlappingUsers, username, showEveryone]);

	const totalOverlapping = React.useMemo(() => {
		if (!showEveryone) return 0;
		let total = 0;
		for (const intervalArray of Object.values(intervals)) {
			total += intervalArray.filter((v) => v.contains(hour)).length;
		}
		return total;
	}, [intervals, hour, showEveryone]);

	let percentage = React.useMemo(() => totalOverlapping / (totalUsers + 1), [totalOverlapping, totalUsers]);

	const setStart = React.useCallback(
		(e) => {
			// if not left click
			if (e.nativeEvent.which !== 1) return;
			if (!username) {
				setshowWarning(true);
				setTimeout(() => {
					setshowWarning(false);
				}, 2000);
				return;
			}

			dispatch(setDeleting(userSelected));
			dispatch(setSelectStartDate(hour));
			dispatch(setSelectEndDate(hour));
		},
		[hour, userSelected, dispatch, username]
	);

	const setEnd = React.useCallback(() => {
		if (!selection.currentStart) return;
		dispatch(setSelectEndDate(hour));
	}, [selection, hour, dispatch]);

	const onMouseOver = React.useCallback(() => {
		setEnd();
	}, [setEnd]);

	let className = "clickable-time";

	if (othersOverlapping.length > 0) {
		className += " has-other-users";
	}

	if (userSelected || currentlySelected) {
		// percentage = 1;
		className += " user-selected";
	}
	

	percentage = Math.max(0.2, percentage);

	const showNumber = userSelected || othersOverlapping.length > 0;

	const trigger = (
		<div
			className={className}
			style={{ "--percentage": percentage }}
			onMouseDown={setStart}
			onMouseEnter={onMouseOver}
		>
			{!showWarning && showNumber && (
				<div>
					{totalOverlapping > 1 && (totalOverlapping || 1)+" "}<Icon name="user" size="small" />
				</div>
			)}
			{showWarning && <div style={{ color: "red", fontWeight: "bold" }}>{_("Enter your name first!")}</div>}
		</div>
	);

	const popupStyle = {
		borderRadius: 0,
		opacity: 0.99,
		padding: 10,
	};

	const content = (
		<>
			<Popup.Header className="block-popup-name">{hour.toFormat("t")}</Popup.Header>
			<Popup.Content>
				<UserList usersArray={overlappingUsers} />
			</Popup.Content>
		</>
	);

	return (
		<Popup
			disabled={!!selection.currentStart}
			className="transition fade in"
			style={popupStyle}
			inverted
			position="left center"
			preferPlace="left"
			trigger={trigger}
			mouseEnterDelay={250}
		>
			{content}
		</Popup>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		selection: state.selection,
		intervals: state.intervals,
		username: state.users.currentUsername,
		totalUsers: state.users.allUsernames.length,
		showEveryone: state.ui.showEveryone,
	};
};

export default connect(mapStateToProps)(Hour);
