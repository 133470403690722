export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitforCondition(conditionTest) {
	return new Promise(async (resolve) => {
        while(true){
            await sleep(100);
            if(conditionTest()){
                resolve();
                break;
            }
        }
    });
}
