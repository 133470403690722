import * as React from "react";
import UserItem from "./UserItem";

function UserList({ usersArray }) {
	return (
		<div className="users-list">
			{usersArray.map((u, idx) => (
				<UserItem key={idx} username={u} />
			))}
		</div>
	);
}

export default UserList;
