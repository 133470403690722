/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCalendar = /* GraphQL */ `
  mutation CreateCalendar(
    $input: CreateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    createCalendar(input: $input, condition: $condition) {
      id
      name
      description
      url
      intervals {
        items {
          id
          username
          calendarID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCalendar = /* GraphQL */ `
  mutation UpdateCalendar(
    $input: UpdateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    updateCalendar(input: $input, condition: $condition) {
      id
      name
      description
      url
      intervals {
        items {
          id
          username
          calendarID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCalendar = /* GraphQL */ `
  mutation DeleteCalendar(
    $input: DeleteCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    deleteCalendar(input: $input, condition: $condition) {
      id
      name
      description
      url
      intervals {
        items {
          id
          username
          calendarID
          startDate
          endDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInterval = /* GraphQL */ `
  mutation CreateInterval(
    $input: CreateIntervalInput!
    $condition: ModelIntervalConditionInput
  ) {
    createInterval(input: $input, condition: $condition) {
      id
      username
      calendarID
      calendar {
        id
        name
        description
        url
        intervals {
          nextToken
        }
        createdAt
        updatedAt
      }
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const updateInterval = /* GraphQL */ `
  mutation UpdateInterval(
    $input: UpdateIntervalInput!
    $condition: ModelIntervalConditionInput
  ) {
    updateInterval(input: $input, condition: $condition) {
      id
      username
      calendarID
      calendar {
        id
        name
        description
        url
        intervals {
          nextToken
        }
        createdAt
        updatedAt
      }
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteInterval = /* GraphQL */ `
  mutation DeleteInterval(
    $input: DeleteIntervalInput!
    $condition: ModelIntervalConditionInput
  ) {
    deleteInterval(input: $input, condition: $condition) {
      id
      username
      calendarID
      calendar {
        id
        name
        description
        url
        intervals {
          nextToken
        }
        createdAt
        updatedAt
      }
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
