import * as React from "react";
import { connect } from "react-redux";
import { Checkbox } from "semantic-ui-react";
import { _ } from "../i18n/i18n";
import { setShowEveryone } from "../store/slices/uiSlice";

function ToggleEveryoneOption({ dispatch, users, showEveryone }) {
	if (!users.allUsernames.some((u) => u !== users.currentUsername)) {
		return null;
	}
	return (
		<div className="toggle-everyone-container">
			<Checkbox
				toggle
				checked={showEveryone}
				label={_("Show everyone")}
				onClick={(evt, data) => dispatch(setShowEveryone(data.checked))}
			/>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		users: state.users,
		showEveryone: state.ui.showEveryone,
	};
};

export default connect(mapStateToProps)(ToggleEveryoneOption);
