import * as React from "react";

const { DateTime } = require("luxon");

function Day({ date }) {
	const isToday = React.useMemo(() => {
		const now = DateTime.now();
		if (date.day !== now.day) return false;
		if (date.month !== now.month) return false;
		if (date.year !== now.year) return false;

		return true;
	}, [date]);

	return (
		<div className={["day", isToday && "today"].toStyle()}>
			<div className="desktop-only">
				<div className="number">{date.toFormat("DD")}</div>
				<div className="name">{date.toFormat("cccc")}</div>
			</div>
			<div className="mobile-large-only">
				<div className="number">{date.toFormat("d")}</div>
				<div className="name">{date.toFormat("ccc")}</div>
			</div>
			<div className="mobile-small-only">
				<div className="number">{date.toFormat("d")}</div>
				<div className="name">{date.toFormat("ccccc")}</div>
			</div>
		</div>
	);
}

export default Day;
