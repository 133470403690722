import * as React from "react";

function Logo() {

    return (
        <div className="logo">
            When do we meet?
        </div>
    );
}

export default Logo;