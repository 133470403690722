import * as React from "react";
import { connect } from "react-redux";
import { setFocusedDate } from "../store/slices/dateNavigationSlice";
import AppButton from "./buttons/AppButton";

function DateNavigator({ dispatch, focusedDate }) {
	const goPrevious = React.useCallback(() => dispatch(setFocusedDate(focusedDate.plus({ weeks: -1 }))), [
		focusedDate,
		dispatch,
	]);

	const goNext = React.useCallback(() => dispatch(setFocusedDate(focusedDate.plus({ weeks: 1 }))), [
		focusedDate,
		dispatch,
	]);

	const weekStart = focusedDate.startOf("week");
	const weekEnd = focusedDate.endOf("week");

	return (
		<div className="date-navigator">
			<div className="week-arrow-container">
				<AppButton
					className="transparent"
					onClick={goPrevious}
					icon="caret square left"
					size="massive"
				></AppButton>
				<div>
					<div className="title">
						{weekStart.toFormat("DD")} - {weekEnd.toFormat("DD")}
					</div>
				</div>
				<AppButton
					className="transparent"
					onClick={goNext}
					icon="caret square right"
					size="massive"
				></AppButton>
			</div>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		focusedDate: state.dateNavigation.focusedDate,
	};
};

export default connect(mapStateToProps)(DateNavigator);
