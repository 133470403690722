import { createSlice } from "@reduxjs/toolkit";
import Interval from "luxon/src/interval.js";
import { selectionToIntervals } from "../../tools/dates";
import { saveUserItervals } from "./intervalsSlice";

export const selectionSlice = createSlice({
	name: "selectionSlice",
	initialState: {
		currentStart: null,
		currentEnd: null,
		deleting: false,
		currentIntervals: [],
		savedIntervals: [],
	},
	reducers: {
		setSelectStartDate: (state, { payload: date }) => {
			state.currentStart = date;
			state.currentIntervals = selectionToIntervals(state.currentStart, state.currentEnd);
		},
		setSelectEndDate: (state, { payload: date }) => {
			state.currentEnd = date;
			state.currentIntervals = selectionToIntervals(state.currentStart, state.currentEnd);
		},
		setDeleting: (state, { payload: deleting }) => {
			state.deleting = deleting;
		},
		endSelectMode: (state) => {
			state.currentStart = null;
			state.currentEnd = null;
			state.currentIntervals = [];
		},
	},
});

export const {
	setSelectStartDate,
	setSelectEndDate,
	oldsaveSelection,
	setDeleting,
	endSelectMode,
} = selectionSlice.actions;

export function saveSelection(date) {
	return async (dispatch, getState) => {
		const state = getState();

		const username = state.users.currentUsername;

		if (!state.selection.currentStart) return;
		if (!state.selection.currentEnd) return;
		
		let intervals = selectionToIntervals(state.selection.currentStart, state.selection.currentEnd);

		const existingIntervals = state.intervals[username] || [];

		let newItervals;
		if (state.selection.deleting) {
			newItervals = Interval.xor([...intervals, ...existingIntervals]);
			// make sure nothing new is created
			newItervals = newItervals.filter((i) => existingIntervals.some((sin) => sin.overlaps(i)));
		} else {
			newItervals = Interval.merge([...intervals, ...existingIntervals]);
		}

		dispatch(saveUserItervals(username, newItervals));
		dispatch(endSelectMode());
	};
}
