import * as React from "react";
import { connect } from "react-redux";
import { _ } from "../i18n/i18n";
import { updateCurrentCalendar } from "../store/slices/calendarInfoSlice";
import EditableText from "./text/EditableText";

function CalendarInfo({ dispatch, calendarInfo }) {
	return (
		<div className="calendar-info">
			<EditableText
				style={{ marginBottom: 12 }}
				value={calendarInfo.name}
				placeholder={_("Unnamed event", "calendar default name")}
				onChange={(value) => dispatch(updateCurrentCalendar({ name: value }))}
				className="calendar-title"
			/>
			<EditableText
				value={calendarInfo.description}
				placeholder={_("add description", "calendar default description")}
				useTextArea
				onChange={(value) => dispatch(updateCurrentCalendar({ description: value }))}
			/>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		calendarInfo: state.calendarInfo,
	};
};

export default connect(mapStateToProps)(CalendarInfo);
