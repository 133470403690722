import { createSlice } from "@reduxjs/toolkit";

const { DateTime } = require("luxon");

export const dateNavigationSlice = createSlice({
	name: "dateNavigation",
	initialState: {
		focusedDate: DateTime.now().startOf("week"),
	},
	reducers: {
		setFocusedDate: (state, { payload: date }) => {
			state.focusedDate = date;
		},
	},
});

export const { setFocusedDate } = dateNavigationSlice.actions;
