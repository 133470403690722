import * as React from "react";
import { connect } from 'react-redux';
import { Container, Loader } from "semantic-ui-react";
import { _ } from "../i18n/i18n";

function SavingBar({dispatch, saving}) {

	
	const onBeforeUnload  =React.useCallback((e)=>{
		if (saving) {
			e.preventDefault();
			e.returnValue = '';
			return;
		}	
		delete e['returnValue'];
	}, [saving]);

	React.useEffect(()=>{
		if(saving){
			window.addEventListener('beforeunload', onBeforeUnload);
		}

		return ()=>{
			window.removeEventListener('beforeunload', onBeforeUnload);
		}
	}, [onBeforeUnload]);


	

	return (
		<div className={["saving-bar", !saving && "hidden"].toStyle()}>
			<Container className="content">
				<Loader active inline size="tiny" />
				<p style={{marginLeft: 4}}>{_("Saving...")}</p>
			</Container>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => {
    return {
        saving: state.ui.saving
    };
};

export default connect(mapStateToProps)(SavingBar);
