import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
	name: "ui",
	initialState: {
		showEveryone: true,
		loadingCalendar: false,
		saving: false,
	},
	reducers: {
		setShowEveryone: (state, { payload: flag }) => {
			state.showEveryone = flag;
		},
		setLoadingCalendar: (state, { payload: flag }) => {
			state.loadingCalendar = flag;
		},
		setSaving: (state, { payload: flag }) => {
			state.saving = flag;
		},
	},
});

export const { setShowEveryone, setLoadingCalendar, setSaving } = uiSlice.actions;
