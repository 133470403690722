import * as React from "react";
import { connect } from "react-redux";
import { Icon, Input, Message } from "semantic-ui-react";
import { _ } from "../i18n/i18n";
import { saveUsername } from "../store/slices/usersSlice";
import EditableText from "./text/EditableText";

function UserInfo({ dispatch, username }) {
	const [temporaryName, settemporaryName] = React.useState("");

	if (!username) {
		return (
			<div className="userinfo">
				<Message negative={!temporaryName}>{_("Please enter your name before entering your availability")}</Message>
				<Input
					error={!temporaryName}
					value={temporaryName}
					onChange={(e) => settemporaryName(e.target.value)}
					action={{
						icon: "check",
						onClick: () => dispatch(saveUsername(temporaryName)),
						disabled: !temporaryName,
					}}
				/>
			</div>
		);
	}

	return (
		<div className="userinfo">
			<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
				<Icon name="user" style={{ bottom: 3, marginRight: 12, position: "relative" }} />
				<EditableText
					value={username}
					onChange={(value) => dispatch(saveUsername(value))}
					placeholder={_("Your name")}
				/>
			</div>

			<div className="hint">
				{_("Click and drag areas on the calendar to share your availability for this event.")}
			</div>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		username: state.users.currentUsername,
	};
};

export default connect(mapStateToProps)(UserInfo);
