// eslint-disable-next-line
Array.prototype.pl_groupby = function (key) {
	return this.reduce(function (result, x) {
		(result[x[key]] = result[x[key]] || []).push(x);
		return result;
	}, {});
};

Array.prototype.pl_first = function () {
	if (this.length <= 0) return null;
	return this[0];
};

Array.prototype.toStyle = function () {
	return this.filter((t) => !!t).join(" ");
};

Array.prototype.pl_removeSimple = function (element) {
	let array = this.slice();
	let index = array.indexOf(element);

	if (index > -1) {
		array.splice(index, 1);
	}
	return array;
};