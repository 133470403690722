import { createSlice } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { waitforCondition } from "../../tools/promises";
import { setCalendar } from "./calendarInfoSlice";
import { setSaving } from "./uiSlice";
import { addUsername } from "./usersSlice";

let currentSaveQueryHash = null;
let queuedQueries = [];

export const intervalsSlice = createSlice({
	name: "intervals",
	// username: []
	initialState: {},
	reducers: {
		setLuxonIntervals: (state, { payload: { username, luxonIntervals } }) => {
			state[username] = luxonIntervals;
		},
		setAllIntervals: (state, { payload: luxonIntervalsMap }) => {
			for (const [key, value] of Object.entries(luxonIntervalsMap)) {
				state[key] = value;
			}
		},
	},
});

export const { setLuxonIntervals, setAllIntervals } = intervalsSlice.actions;

export function saveAllIntervals() {
	return async (dispatch, getState) => {
		const state = getState();

		for (const [username, intervals] of Object.entries(state.intervals)) {
			dispatch(saveUserItervals(username, intervals));
		}
	};
}

export function saveUserItervals(username, luxonIntervals) {
	return async (dispatch, getState) => {
		
		dispatch(addUsername(username));
		dispatch(setLuxonIntervals({ username, luxonIntervals }));

		const thisQueryHash = Date.now();
		currentSaveQueryHash = thisQueryHash;
		queuedQueries.push(thisQueryHash);

		// Wait for this query to be first
		if (queuedQueries[0] !== currentSaveQueryHash) {
			// wait until the others are done
			await waitforCondition(() => queuedQueries[0] === thisQueryHash);
			// but if there are others waiting, don't do that one either
			if (queuedQueries.length > 1) {
				queuedQueries = queuedQueries.pl_removeSimple(thisQueryHash);
				return;
			}
		}

		const state = getState();

		let calendar = state.calendarInfo;
		const calendarID = calendar.id;
		if (!calendarID) return;

		dispatch(setSaving(true));

		const userIntervals = calendar.intervals.items.filter((i) => i.username === username);

		if (userIntervals.length > 0) {
			for (const interval of userIntervals) {
				try {
					// Api call
					API.graphql(
						graphqlOperation(mutations.deleteInterval, {
							input: { id: interval.id },
						})
					).catch((e) => {
						console.log("error while deleting intervals: ", e);
					});
				} catch (e) {
					dispatch(setSaving(false));
					console.log("error while deleting intervals: " + JSON.stringify(e));
					return;
				}
			}
		}

		let intervalsData = luxonIntervals
			.filter((i) => !!i.start && !!i.end)
			.map((i) => ({
				username,
				calendarID,
				startDate: i.start?.toMillis().toString(),
				endDate: i.end?.toMillis().toString(),
			}));

		const savedData = [];
		
		for (const element of intervalsData) {
			if (currentSaveQueryHash !== thisQueryHash) {
				break;
			}
			try {
				// Api call
				const response = await API.graphql(graphqlOperation(mutations.createInterval, { input: element }));

				const createdInterval = response.data.createInterval;
				savedData.push(createdInterval);
			} catch (e) {
				console.error("error while creating intervals: " + JSON.stringify(e));
				dispatch(setSaving(false));
				return;
			}
		}

		// Update calendar info
		const othersIntervalItems = calendar.intervals.items.filter((i) => i.username !== username);
		const updatedIntervalItems = [...othersIntervalItems, ...savedData];
		const newIntervalsValue = { ...calendar.intervals, items: updatedIntervalItems };
		const newCalendarInfo = { ...calendar, intervals: newIntervalsValue };

		dispatch(setCalendar(newCalendarInfo));

		queuedQueries = queuedQueries.pl_removeSimple(thisQueryHash);

		if (queuedQueries.length === 0) {
			dispatch(setSaving(false));
		}
	};
}
