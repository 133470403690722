import * as React from 'react';
import { Button } from 'semantic-ui-react';

function AppButton(props) {

    return (
        <Button {...props}>
            {props.children}
        </Button>
    );
}

export default AppButton;