import PropTypes from "prop-types";
import { Sticky } from "semantic-ui-react";
import { getHours } from "../tools/dates";
import Day from "./Day";
import Hours from "./Hours";

const { DateTime } = require("luxon");

function Week({ focusedDate, appRef }) {
	const days = [focusedDate.startOf("week")];
	const hours = getHours(focusedDate);

	for (let i = 1; i < 7; i++) {
		days[i] = days[i - 1].plus({ days: 1 });
	}

	return (
		<div className="week">
			<Sticky context={appRef}>
				<div className="top-bar">
					<div className="hint" style={{ textAlign: "center" }}>
						{DateTime.now().toFormat("ZZZZ")}
					</div>
					<div className="days">
						{days.map((d, idx) => (
							<Day key={idx} date={d} />
						))}
					</div>
				</div>
			</Sticky>

			<div className="table">
				<div className="hours-column">
					{hours.map((d, idx) => (
						<div key={idx} className="hour-label">
							{d.toFormat("t")}
						</div>
					))}
				</div>
				<div className="options">
					{days.map((d, idx) => (
						<Hours key={idx} date={d} />
					))}
				</div>
			</div>
		</div>
	);
}

export default Week;

Week.propTypes = {
	focusedDate: PropTypes.instanceOf(DateTime),
};
